<template>
  <div>
    <div class="row">
      <div class="col">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Sección de nosotros',
              active: true
            }
          ]"
        ></vs-breadcrumb>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <vs-tabs>
          <vs-tab label="Reseña">
            <div class="con-tab-ejemplo">
              <label for="">Titulo de la reseña</label>
              <vs-textarea v-model="about.title" />

              <label for="">Reseña</label>
              <textarea class="form-control mb-2" rows="10" v-model="about.description"/>

              <vs-button icon="save" @click="updateDescription" type="gradient">Guardar</vs-button>
            </div>
          </vs-tab>
          <vs-tab label="Imagen lateral">
            <div class="con-tab-ejemplo">
              <div class="row">
                <div class="col-6">
                  <img v-if="about.side_photo" class="img-responsive img-about" :src="`${about.src}/${about.side_photo}`" alt=""><br>
                  <div class="button-wrapper">
                    <span class="label">
                      {{file_name}}
                    </span>
                    <input @change="handleFile" type="file" id="upload" class="upload-box" placeholder="Subir archivo">
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
	import aboutService from '@/services/about.service'

	export default {
		data: () => ({
      file_name: 'Seleccionar imagen para modificar',
			about: {
        title: '',
				description: ''
			},
			loading_description: false,
			loading_side_photo: false,
			side_photo: ''
		}),
		methods: {
			async getDataAbout() {
        this.$vs.loading({
          type: 'point'
        })
				const { data:res } = await aboutService.getDataAbout()
        this.about = res.data
        this.$vs.loading.close()
			},
			async updateSidePhoto() {
				if (this.side_photo != '') {
					this.loading_side_photo = true
					let frmData = new FormData()
					frmData.append('image', this.side_photo)
					const { data:res } = await aboutService.updateSidePhoto(frmData)
					if (res.success) {
						this.about.side_photo = res.data.side_photo
						this.side_photo = ''
						this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
					}
					this.loading_side_photo = false
				}
			},
			async updateDescription() {
				this.loading_description = true
				const { data:res } = await aboutService.updateDescription({
          title: this.about.title,
					description: this.about.description
				})
				if (res.success) {
					this.$vs.notify({
            title: res.message,
            text:'',
            color: 'success',
            position: 'top-right'
          })
				}
				this.loading_description = false
			},
			handleFile(e) {
				if (e != undefined) {
          this.file_name = e.target.files[0].name
          this.side_photo = e.target.files[0]

          this.updateSidePhoto()
				}
			},
		},
		created() {
			this.getDataAbout()
		}
	}
</script>

<style scoped>
	.img-about {
		width: 450px;
		height: 675px;
		max-height: 400px;
		border-radius: 5px
	}
</style>

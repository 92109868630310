var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vs-breadcrumb',{attrs:{"items":[
          {
            title: 'Admin Tag Store',
            url: '/'
          },
          {
            title: 'Sección de nosotros',
            active: true
          }
        ]}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vs-tabs',[_c('vs-tab',{attrs:{"label":"Reseña"}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('label',{attrs:{"for":""}},[_vm._v("Titulo de la reseña")]),_c('vs-textarea',{model:{value:(_vm.about.title),callback:function ($$v) {_vm.$set(_vm.about, "title", $$v)},expression:"about.title"}}),_c('label',{attrs:{"for":""}},[_vm._v("Reseña")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.about.description),expression:"about.description"}],staticClass:"form-control mb-2",attrs:{"rows":"10"},domProps:{"value":(_vm.about.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.about, "description", $event.target.value)}}}),_c('vs-button',{attrs:{"icon":"save","type":"gradient"},on:{"click":_vm.updateDescription}},[_vm._v("Guardar")])],1)]),_c('vs-tab',{attrs:{"label":"Imagen lateral"}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.about.side_photo)?_c('img',{staticClass:"img-responsive img-about",attrs:{"src":((_vm.about.src) + "/" + (_vm.about.side_photo)),"alt":""}}):_vm._e(),_c('br'),_c('div',{staticClass:"button-wrapper"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.file_name)+" ")]),_c('input',{staticClass:"upload-box",attrs:{"type":"file","id":"upload","placeholder":"Subir archivo"},on:{"change":_vm.handleFile}})])])])])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
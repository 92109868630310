import api from "../api"

class AboutService {

	static getDataAbout() {
		return api({
			method: 'get',
			url: 'about'
		})
	}

	static updateDescription(data) {
		return api({
			method: 'put',
			data: data,
			url: 'about-description'
		})
	}

	static updateSidePhoto(data) {
		return api({
			method: 'post',
			data: data,
			url: 'about-side-photo'
		})
	}

}

export default AboutService
